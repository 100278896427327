
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Inject, Provide } from '@/library/provide';
import mixinPage from '@/mixins/mixinPage';
import EmployeeeachSelfBaseName from "@/components/employeeeach/self/base/Name.vue";
import EmployeeeachSelfGeneralSex from "@/components/employeeeach/self/general/Sex.vue";
import EmployeeeachSelfGeneralBirthdate from "@/components/employeeeach/self/general/Birthdate.vue";
import EmployeeeachSelfGeneralMynum from "@/components/employeeeach/self/general/Mynum.vue";
import EmployeeeachSelfGeneralMail from "@/components/employeeeach/self/general/Mail.vue";

@Component({
  components: {
    EmployeeeachSelfBaseName,
    EmployeeeachSelfGeneralSex,
    EmployeeeachSelfGeneralBirthdate,
    EmployeeeachSelfGeneralMynum,
    EmployeeeachSelfGeneralMail,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() branch_hash: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.manager.object.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }

}
