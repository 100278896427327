
  import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
  import mixinNenchoEmployee from "@/mixins/mixinNenchoEmployee";
  import validate from "@/validations/validate";
  import {
    validationEmployeeMail,
    validationEmployeeRoute,
    validationEmployeeStep1,
    validationEmployeeStep2,
    validationEmployeeStep3,
    validationEmployeeStep4,
  } from "@/validations/employee";
  import validate_api from "@/validations/validate_api";
  import * as util from "@/util";
  import { validationDependant, validationSpouce } from "@/validations/family";
  import { Provide } from "@/library/provide";
  import LProgress from "@/components/l/Progress.vue";
  import LWizard from "@/components/l/Wizard.vue";
  import LBread from "@/components/l/Bread.vue";
  import EmployeenenchoBase from "@/components/employeenencho/Base.vue";
  import EmployeejoinAddress from "@/components/employeejoin/Address.vue";
  import EmployeejoinBank from "@/components/employeejoin/Bank.vue";
  import EmployeejoinTax from "@/components/employeejoin/Tax.vue";
  import EmployeejoinSpouce from "@/components/employeejoin/Spouce.vue";
  import EmployeejoinDependants from "@/components/employeejoin/Dependants.vue";
  import EmployeenenchoDependants from "@/components/employeenencho/Dependants.vue";
  import EmployeejoinEmployeeinfo from "@/components/employeejoin/Employeeinfo.vue";
  import LCard from "@/components/l/Card.vue";
  import FButton from "@/components/f/Button.vue";
  import EmployeenenchoDependantsdisp from "@/components/employeenencho/Dependantsdisp.vue";
  import CommonBigbutton from "@/components/common/Bigbutton.vue";

  @Component({
    components: {
      LProgress,
      LWizard,
      LBread,
      EmployeenenchoBase,
      EmployeejoinAddress,
      EmployeejoinBank,
      EmployeejoinTax,
      EmployeejoinSpouce,
      EmployeenenchoDependants,
      EmployeejoinEmployeeinfo,
      LCard,
      FButton,
      EmployeenenchoDependantsdisp,
      CommonBigbutton,
    },
  })
  export default class Main extends Mixins(mixinNenchoEmployee) {
    @Prop() is_finish_employee;

    async created() {
      this.save = false;
      this.m.initState({ name: "step" });
      this.m.setState({ name: "step", val: 0.1 });
      this.m.initEditManager({ name: "nencho_employee" });
      await this.fetchedGlobal(this.is_valid);
    }

    get is_valid() {
      return !!this.global && !!this.employeely;
    }

    save: any = false;

    @Watch("is_valid")
    async fetchedGlobal(is_valid) {
      if (is_valid) {
        if (this.is_finish_employee == 1) {
          this.save = true;
          const spouce = this.employeely.spouce ?? {
            employee_id: this.employeely.employee.id,
            kana: null,
            name: null,
            birth_date: null,
            mynum: null,
            is_housemate: 1,
            disability_type: 0,
            income: 0,
            retire_income: 0,
            other_income: 0,
            is_not_live: 0,
            address: null,
            not_live_money: null,
            is_special_disability_housemate: 1,
            disability_reason: null,
          };
          spouce.retire_income = spouce.retire_income ?? 0;
          this.m.edit_manager.nencho_employee.edit({
            employee: {
              ...this.employeely.employee,
              is_spouce_deduction: this.employeely.spouce ? 1 : 0,
              carfare_type: this.employeely.employee.carfare_type == 0 ? 0 : 1,
            },
            spouce,
            dependants: this.employeely.dependants,
            dependant_num: this.employeely.dependants.length,
          });
          this.m.setState({ name: "step", val: this.steps["confirm"].index });
        } else {
          this.save = await this.nm.getSave({
            nencho_id: this.nencho_id,
            employee_id: this.employee_id,
            kind: "employee",
          });
          if (this.save !== null) {
            this.m.edit_manager.nencho_employee.edit({
              employee: this.save.content.employee,
              spouce: this.save.content.spouce,
              dependants: this.save.content.dependants,
              dependant_num: this.save.content.dependants.length,
            });
            this.m.setState({ name: "step", val: this.save.content.step });
          } else {
            const spouce = this.employeely.spouce ?? {
              employee_id: this.employeely.employee.id,
              kana: null,
              name: null,
              birth_date: null,
              mynum: null,
              is_housemate: 1,
              disability_type: 0,
              income: 0,
              retire_income: 0,
              other_income: 0,
              is_not_live: 0,
              address: null,
              not_live_money: null,
              is_special_disability_housemate: 1,
              disability_reason: null,
            };
            spouce.retire_income = spouce.retire_income ?? 0;
            this.m.edit_manager.nencho_employee.edit({
              employee: {
                ...this.employeely.employee,
                is_spouce_deduction: this.employeely.spouce ? 1 : 0,
                carfare_type: this.employeely.employee.carfare_type == 0 ? 0 : 1,
              },
              spouce,
              dependants: this.employeely.dependants,
              dependant_num: this.employeely.dependants.length,
            });
            this.m.setState({ name: "step", val: 0.1 });
          }
        }
      }
    }

    get employee() {
      return this.m.edit_manager.nencho_employee.object.employee;
    }
    get dependants() {
      return this.m.edit_manager.nencho_employee.object.dependants;
    }
    get spouce() {
      return this.m.edit_manager.nencho_employee.object.spouce;
    }
    get dependant_num() {
      return this.m.edit_manager.nencho_employee.object.dependant_num;
    }

    async clickNext() {
      if (this.step_key == "initial") {
        this.m.setState({ name: "step", val: this.steps[this.steps["initial"].next_index].index });
      } else if (this.step_key == "base") {
        if (validate(validationEmployeeStep1(this.employee))) {
          if (
            validate(
              await validationEmployeeMail(this, {
                employee: this.employee,
                employee_id: this.employee_id,
              }),
            )
          ) {
            this.m.setState({ name: "step", val: this.steps[this.steps["base"].next_index].index });
          }
        }
      } else if (this.step_key == "address") {
        let error = validationEmployeeStep2(this.employee);
        error = error.concat(validationEmployeeRoute(this.employee));
        if (validate(error)) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["address"].next_index].index,
          });
        }
      } else if (this.step_key == "bank") {
        if (validate(validationEmployeeStep3(this.employee))) {
          this.m.setState({ name: "step", val: this.steps[this.steps["bank"].next_index].index });
        }
      } else if (this.step_key == "tax") {
        if (validate(validationEmployeeStep4(this.employee, true))) {
          if (this.employee.tax_type == 0) {
            this.m.setState({
              name: "step",
              val: this.steps[this.steps["tax"].next_kou_index].index,
            });
          } else {
            this.m.setState({
              name: "step",
              val: this.steps[this.steps["tax"].next_otsu_index].index,
            });
          }
        }
      } else if (this.step_key == "spouce") {
        if (
          this.employee.is_spouce == 0 ||
          this.employee.is_spouce_deduction == 0 ||
          validate(validationSpouce(null, { spouce: this.spouce, index: true }))
        ) {
          this.m.setState({ name: "step", val: this.steps[this.steps["spouce"].next_index].index });
        }
      } else if (this.step_key == "dependant") {
        let error = [];
        if (this.dependant_num == 1) {
          error = error.concat(
            validationDependant(null, {
              dependant: this.dependants[0],
              is_spouce: this.employee.is_spouce == 1,
              index: 0,
            }),
          );
        } else {
          for (let index = 0; index < this.dependant_num; index++) {
            error = error.concat(
              validationDependant(null, {
                dependant: this.dependants[index],
                is_spouce: this.employee.is_spouce == 1,
                index: index + 1,
              }),
            );
          }
        }
        if (validate(error)) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["dependant"].next_index].index,
          });
        }
      } else if (this.step_key == "confirm") {
        this.nm.updateNenchoEmployee({
          nencho_id: this.nencho_id,
          employee_id: this.employee_id,
          employee: this.m.edit_manager.nencho_employee.object.employee,
          spouce: this.m.edit_manager.nencho_employee.object.spouce,
          dependants: this.m.edit_manager.nencho_employee.object.dependants,
          query: this.query,
        });
      }
    }
    clickPrev() {
      if (this.step_key == "base") {
        this.m.setState({ name: "step", val: this.steps[this.steps["base"].prev_index].index });
      } else if (this.step_key == "address") {
        this.m.setState({ name: "step", val: this.steps[this.steps["address"].prev_index].index });
      } else if (this.step_key == "bank") {
        this.m.setState({ name: "step", val: this.steps[this.steps["bank"].prev_index].index });
      } else if (this.step_key == "tax") {
        this.m.setState({ name: "step", val: this.steps[this.steps["tax"].prev_index].index });
      } else if (this.step_key == "spouce") {
        this.m.setState({ name: "step", val: this.steps[this.steps["spouce"].prev_index].index });
      } else if (this.step_key == "dependant") {
        this.m.setState({
          name: "step",
          val: this.steps[this.steps["dependant"].prev_index].index,
        });
      } else if (this.step_key == "confirm") {
        if (this.employee.tax_type == 0) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["confirm"].prev_kou_index].index,
          });
        } else {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["confirm"].prev_otsu_index].index,
          });
        }
      }
    }

    @Provide("employee_type")
    get employee_type() {
      return this.employee.type;
    }

    @Provide("is_edit")
    get is_edit() {
      return this.step < this.steps["confirm"].index;
    }
    @Provide("is_add")
    get is_add() {
      return false;
    }

    @Provide("force_sp")
    get force_sp() {
      return true;
    }
  }
